import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import axios from 'axios';
import * as FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

const Container = styled.div`
  padding: 20px;
  text-align: center;
`; 

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const DataDisplay = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  text-align: center;
`;

const Dropdown = styled.select`
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;





function Testwisedata() {
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedDateTotalPrice, setSelectedDateTotalPrice] = useState([]);
  const [lab, setLab] = useState('lab05'); // Default lab value
  const [clientdata, setClientdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set the number of rows per page
  const [totalbooking, settotalbooking] = useState(0);

  const navigate = useNavigate();
  // Calculate the starting index and ending index for the current page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Change page handler
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Change rows per page handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };


  const handleDateSubmit = async () => {
    try {
      
      // Fetch data based on selected date range and lab
      const response = await axios.post('https://apiv2.labridge.in/misdata/test_counts', {
        startDate,
        endDate,
        lab,
        isDownload: false,
      });
  
      setSelectedDateTotalPrice(response.data);
  
      // Use Promise to wait for forEach loop to complete
      await new Promise((resolve) => {
        settotalbooking(0);
      
        response.data.forEach((element, index, array) => {
         
          settotalbooking((prevTotalBooking) => prevTotalBooking + Number(element.frequency));

          // Check if it's the last iteration to resolve the Promise
          if (index === array.length - 1) {
            resolve();
          }
        });
      });
  
    } catch (error) {
      console.error(error);
    }
  };
  const downloadexcel = async () => {
    try {
      
      // Fetch data based on selected date range and lab
      const response = await axios.post('https://apiv2.labridge.in/misdata/test_counts', {
        startDate,
        endDate,
        lab,
        isDownload: true,
      },{ responseType: 'blob' });
      FileSaver.saveAs(response.data, 'order-stats.xlsx');
  
    } catch (error) {
      console.error(error);
    }
  };
async function dateget(){
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  // Set today's date as the initial state
  setStartDate(formattedDate);
  setEndDate(formattedDate);
}
console.log(selectedDateTotalPrice);
useEffect(() => {
  const isLoggedIn = localStorage.getItem('loginData');
  

  // If not logged in, redirect to the login page
  if (!isLoggedIn) {
    navigate('/login');
  } else {
    // If logged in, proceed with your logic
    handleDateSubmit();
  }
}, []);
  return (
    <div>
      <div className="wrapper">
        {/* Sidebar  */}
        <Navbar/>
        
        {/* Page Content  */}
        <div id="content"> 
        <div>
        <center>
      <h2>Testwise Data</h2>
    </center>
    <Container>
  
   <Form>
        <FormGroup>
          <Label htmlFor="startDate">Start Date</Label>
          <Input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="endDate">End Date</Label>
          <Input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="lab">Lab</Label>
          <Dropdown
            id="lab"
            value={lab}
            onChange={(e) => setLab(e.target.value)}
          >
            <option value="lab05">BRL</option>
            <option value="lab06">zipatho</option>
            <option value="lab08">Nalasopara</option>
            <option value="lab09">Akola</option>
            <option value="lab10">Pune</option>
            <option value="lab11">Patna</option>
          </Dropdown>
        </FormGroup>
      
      </Form>
      <Button  onClick={handleDateSubmit}>
        Show Data
      </Button>
     <button className='btn btn-success ms-2' onClick={()=>{downloadexcel()}}>Download Excel</button>
   </Container>
   <div>
   
   </div>
  
        </div> 
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Test Name</b></TableCell>
              <TableCell align="left"><b>Count</b></TableCell>
              <TableCell align="left"><b>Package</b></TableCell>
              <TableCell align="left"><b>Total Revenue</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? selectedDateTotalPrice.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : selectedDateTotalPrice
            ).map((data) => (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {data.testName}
                </TableCell>
                <TableCell align="left">{data.count}</TableCell>
                <TableCell align="left">{data.ispackage}</TableCell>
                <TableCell align="left">{data.totalRevenue}</TableCell>
   
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={selectedDateTotalPrice.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
          </div>
      </div>
 
  </div>
    
  )
}

export default Testwisedata;