import axios from 'axios';
import * as FileSaver from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import Navbar from '../component/Navbar';

import Serchclient from '../component/Serchclient';
const Container = styled.div`
  padding: 20px;
  text-align: center;
`; 

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    align-items: flex-start;
  }
`;

const Label = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
`;

const Input = styled.input`
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const DataDisplay = styled.div`
  border: 1px solid #ccc;
  padding: 15px;
  text-align: center;
`;

const Dropdown = styled.select`
  padding: 8px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const ClientTestwisedata = () => {
  const [selectedLab, setSelectedLab] = useState(null);
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedDateTotalPrice, setSelectedDateTotalPrice] = useState([]);
  const [lab, setLab] = useState("648ffe442be36ad5f1acb4ec"); // Default lab value
  const [clientdata, setClientdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set the number of rows per page
  const [totalbooking, settotalbooking] = useState(0);
  const [testss, settestss] = useState([]);
  const navigate = useNavigate();
  // Calculate the starting index and ending index for the current page
  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;

  // Change page handler
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Change rows per page handler
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when changing rows per page
  };


  const handleDateSubmit = async () => {
    try {
      
      // Fetch data based on selected date range and lab
      const response = await axios.post('https://apiv2.labridge.in/misdata/api/testFrequencyclient', {
        startDate,
        endDate,
        lab,
        _id:selectedLab._id,
        isDownload: false,
      });
  
      setSelectedDateTotalPrice(response.data);
      settestss(response.data[0].tests);
  
  
    } catch (error) {
      console.error(error);
    }
  };
  const downloadexcelclientwise = async () => {
    try {
      // Determine the correct lab code based on the selected lab ID
      const labCode = lab === "648ffe442be36ad5f1acb4ec"
        ? "lab05"
        : lab === "6585432d4f8639a1d3a6bd02"
        ? "lab08"
        : lab === "65c1157532f8a73e726702d1"
        ? "lab09"
        : lab === "65266c8a27e4b9d512e0d54d"
        ? "lab06"
        : "lab10";
  
      // Fetch data with responseType: 'blob'
      const response = await axios.post(
        'https://apiv2.labridge.in/misdata/api/orders-and-testsclientwise',
        {
          startDate,
          endDate,
          lab: labCode,
          _id: selectedLab._id,
          isDownload: true,
        },
        { responseType: 'blob' } // Ensure response type is blob
      );
  
      // Save the blob data as an Excel file
      FileSaver.saveAs(response.data, 'order-stats.xlsx');
      
    } catch (error) {
      console.error('Error downloading Excel:', error);
    }
  };
async function dateget(){
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  // Set today's date as the initial state
  setStartDate(formattedDate);
  setEndDate(formattedDate);
}
  const downloadexcel = async () => {
    try {
      
      // Fetch data based on selected date range and lab
      const response = await axios.post('https://apiv2.labridge.in/misdata/api/orders-and-tests', {
        startDate,
        endDate,
        lab,
        isDownload: true,
      },{ responseType: 'blob' });
      FileSaver.saveAs(response.data, 'order-stats.xlsx');
  
    } catch (error) {
      console.error(error);
    }
  };
async function dateget(){
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];

  // Set today's date as the initial state
  setStartDate(formattedDate);
  setEndDate(formattedDate);
}
console.log(selectedDateTotalPrice);
useEffect(() => {
 const isLoggedIn = localStorage.getItem('loginData');
  

  // If not logged in, redirect to the login page
  if (!isLoggedIn) {
    navigate('/login');
  } else {
    // If logged in, proceed with your logic
    handleDateSubmit();
  }
}, []);
  const handleSelectLab = (lab) => {
    setSelectedLab({
      _id: lab._id,
      name: lab.labName,
      agent: lab.agent,
    });
  };

  return (
    <div>
      <div className="wrapper">
        {/* Sidebar  */}
        <Navbar/>
        
        {/* Page Content  */}
        <div id="content"> 
        <div>
        <center>
      <h2>Client Ledger</h2>
    </center>
    <Container>
  
   <Form>
        <FormGroup>
          <Label htmlFor="startDate">Start Date</Label>
          <Input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="endDate">End Date</Label>
          <Input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="lab">Lab</Label>
          <Dropdown
            id="lab"
            value={lab}
            onChange={(e) => setLab(e.target.value)}
          >67b2a62aea9fad8f8cb8ea7d
            <option value="648ffe442be36ad5f1acb4ec">Thane</option>
                <option value="6585432d4f8639a1d3a6bd02">Nsp</option>
                <option value="65266c8a27e4b9d512e0d54d">Zipatho</option>
                <option value="65c1157532f8a73e726702d1">Akola</option>
                <option value="66f2b5767dad85c8462efa76">Pune</option>
                <option value="67b2a62aea9fad8f8cb8ea7d">Patna</option>
          </Dropdown>
        </FormGroup>
      
      </Form>
      <Serchclient onSelectLab={handleSelectLab} slabid={lab} />
      {/* <Button  onClick={handleDateSubmit}>
        Show Data
      </Button> */}
     <button className='btn btn-success ms-2' onClick={()=>{downloadexcel()}}>Combined Excel</button>
     <button className='btn btn-success ms-2' onClick={()=>{downloadexcelclientwise()}}>Client Excel</button>
   </Container>
   <div>
   
   </div>
  
        </div> 
       
        {/* <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><b>Test Name</b></TableCell>
              <TableCell align="left"><b>Count</b></TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        
            {testss.map((data) => (
              <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  {data.testName}
                </TableCell>
                <TableCell align="left">{data.frequency}</TableCell>
                
             
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
          </div>
      </div>
 
  </div>
    
  )
};

export default ClientTestwisedata;

