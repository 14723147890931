import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Multiselect from 'multiselect-react-dropdown';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Navbar from '../component/Navbar';

// Styled Components
const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
`;

const Title = styled.h1`
  text-align: center;
  color: #333;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
`;

const Input = styled.input`
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const CheckboxLabel = styled.label`
  margin-left: 8px;
  font-size: 14px;
  color: #555;
`;

const Button = styled.button`
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #45a049;
  }
`;

const ResponseMessage = styled.div`
  margin-top: 16px;
  color: ${(props) => (props.success ? 'green' : 'red')};
  text-align: center;
`;

const DropdownContainer = styled.div`
  margin-bottom: 16px;
`;

function Assigntest() {
  const navigate = useNavigate();
  const [labCodes, setLabCodes] = useState([]);
  const [testId, setTestId] = useState('');
  const [testName, setTestName] = useState('');
  const [testPrice, setTestPrice] = useState('');
  const [mrp, setMrp] = useState('');
  const [tat, setTat] = useState('');

  const [isPackage, setIsPackage] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isTest, setIsTest] = useState(false);

  const [sampleType, setsampleType] = useState('');
  const [container, setContainer] = useState('');
  const [sampleid, setSampleid] = useState('');
  const [sampledatas, setsampledatas] = useState([]);
  const [sampledata, setsampledata] = useState([]);
  const [response, setResponse] = useState({ success: null, msg: '' });

  // Fetch sample data
  useEffect(() => {
    axios
      .get('https://apiv2.labridge.in/admin/sampledata')
      .then((response) => setsampledatas(response.data))
      .catch((error) => console.error('Error fetching sample data:', error));
  }, []);

  const handleCheckboxChange = (checkboxName) => {
    setIsPackage(false);
    setIsProfile(false);
    setIsTest(false);

    if (checkboxName === 'isPackage') setIsPackage(true);
    if (checkboxName === 'isProfile') setIsProfile(true);
    if (checkboxName === 'isTest') setIsTest(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const apiResponse = await axios.post('https://apiv2.labridge.in/admin/addandassignnewtest', {
        labCodes,
        testId,
        testName,
        testPrice,
        mrp,
        tat,
        container,
        isPackage: isPackage ? 1 : 0,
        isProfile: isProfile ? 1 : 0,
        isTest: isTest ? 1 : 0,
        sampleType,
        sampleid,
      });

      if (apiResponse.data.success) {
        alert('Test added and assigned successfully!');
        resetState();
      }
      setResponse(apiResponse.data);
    } catch (error) {
      console.error('Error calling API:', error);
    }
  };

  const resetState = () => {
    setLabCodes([]);
    setTestId('');
    setTestName('');
    setTestPrice('');
    setMrp('');
    setTat('');
    setIsPackage(false);
    setIsProfile(false);
    setIsTest(false);
    setsampleType('');
    setContainer('');
    setSampleid('');
    setsampledata([]);
  };

  return (
    <div className="wrapper">
      <Navbar />
      <div className="container mt-5">
        <Container>
          <Title>Add New Test</Title>
          <Form onSubmit={handleSubmit}>
            <Label>Labs</Label>
            <DropdownContainer>
              <Multiselect
                options={[
                  { labCode: 'lab05', name: 'BRL' },
                  { labCode: 'lab06', name: 'zipatho' },
                  { labCode: 'lab08', name: 'Nalasopara' },
                  { labCode: 'lab09', name: 'Akola' },
                  { labCode: 'lab10', name: 'Pune' },
                ]}
                displayValue="name"
                onSelect={(selectedList) => setLabCodes(selectedList.map((item) => item.labCode))}
                onRemove={(selectedList) => setLabCodes(selectedList.map((item) => item.labCode))}
                placeholder="Select Labs"
              />
            </DropdownContainer>

            <Label>Test ID</Label>
            <Input type="text" value={testId} onChange={(e) => setTestId(e.target.value)} />

            <Label>Test Name</Label>
            <Input type="text" value={testName} onChange={(e) => setTestName(e.target.value)} />

            <Label>Test Price</Label>
            <Input type="text" value={testPrice} onChange={(e) => setTestPrice(e.target.value)} />

            <Label>MRP</Label>
            <Input type="text" value={mrp} onChange={(e) => setMrp(e.target.value)} />

            <Label>TAT</Label>
            <Input type="text" value={tat} onChange={(e) => setTat(e.target.value)} />

            <CheckboxContainer>
              <input
                type="checkbox"
                checked={isPackage}
                onChange={() => handleCheckboxChange('isPackage')}
              />
              <CheckboxLabel>Package</CheckboxLabel>
            </CheckboxContainer>

            <CheckboxContainer>
              <input
                type="checkbox"
                checked={isProfile}
                onChange={() => handleCheckboxChange('isProfile')}
              />
              <CheckboxLabel>Profile</CheckboxLabel>
            </CheckboxContainer>

            <CheckboxContainer>
              <input
                type="checkbox"
                checked={isTest}
                onChange={() => handleCheckboxChange('isTest')}
              />
              <CheckboxLabel>Test</CheckboxLabel>
            </CheckboxContainer>

            <Label>Samples</Label>
            <Multiselect
              options={sampledatas}
              displayValue="sampleType"
              onSelect={(selectedList) => {
                const sampleTypes = selectedList.map((item) => item.sampleType).join(', ');
                const containers = selectedList.map((item) => item.container).join(', ');
                const sampleIds = selectedList.map((item) => item.sampleid).join(', ');

                setsampleType(sampleTypes);
                setContainer(containers);
                setSampleid(sampleIds);
              }}
              onRemove={(selectedList) => {
                const sampleTypes = selectedList.map((item) => item.sampleType).join(', ');
                const containers = selectedList.map((item) => item.container).join(', ');
                const sampleIds = selectedList.map((item) => item.sampleid).join(', ');

                setsampleType(sampleTypes);
                setContainer(containers);
                setSampleid(sampleIds);
              }}
              placeholder="Select Samples"
            />

            <Button type="submit">Submit</Button>
          </Form>

          {response.success !== null && (
            <ResponseMessage success={response.success}>
              {response.success ? 'Test added and assigned successfully!' : `Error: ${response.msg}`}
            </ResponseMessage>
          )}
        </Container>
      </div>
    </div>
  );
}

export default Assigntest;
